import React, { useState, useEffect } from 'react';
import siteData from "../data";
import { useIntersection } from "../tools"

const About = () => {
  const triggerRef = React.useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");
  const [satisfiedHomeowners, setSatisfiedHomeowners] = useState(1000);

  useEffect(() => {

    // Only start incrementing if the current satisfiedHomeowners is less than the siteData value
    if (isVisible && satisfiedHomeowners < siteData.satisfiedHomeowners) {
      const interval = setInterval(() => {
        setSatisfiedHomeowners(prev => {
          // Stop incrementing when the target value is reached
          if (prev >= siteData.satisfiedHomeowners) {
            clearInterval(interval);
            return prev;
          }
          return prev + 1000; // Increment the count by 1
        });
      }, 40); // 2000 milliseconds interval

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [isVisible, satisfiedHomeowners]);

  return (
    <div name='about' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto'>
        <div className='text-center'>
          <h2 className='text-4xl font-bold'>Trusted by homeowners across New Jersey</h2>
          <p className='text-2xl max-w-[1000px] m-auto py-6 text-gray-500'>
            AA Richards Heating & Cooling is a family-owned HVAC heating, ventilation, air conditioning service,
            and installation company serving Middlesex, Monmouth, and Ocean Counties since 1974. Our experienced 
            licensed technicians are specialists in diagnosing, repairing, or installing residential heating and air conditioning systems.
            Recently, we hired additional operations coordinators to ensure the finest scheduling, customer service, and customer satisfaction.
          </p>
        </div>

        <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
          <div className='border py-8 rounded-xl shadow-xl'>
            <p className='text-6xl font-bold text-indigo-600'>100%</p>
            <p className='text-gray-400 mt-2'>Jobs Completed</p>
          </div>
          <div className='border py-8 rounded-xl shadow-xl'>
            <p className='text-6xl font-bold text-indigo-600'>24/7</p>
            <p className='text-gray-400 mt-2'>Emergency Services</p>
          </div>
          <div ref={triggerRef} className='border py-8 rounded-xl shadow-xl'>
            <p className='text-6xl font-bold text-indigo-600'>{numberFormat(satisfiedHomeowners)} +</p>
            <p className='text-gray-400 mt-2'>Satisfied Homeowners</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const numberFormat = (number) => { 
    if (number >= 1000000) {
        return `${(number / 1000000).toFixed(0)}M`; // Format for millions
    } else if (number >= 1000) {
        return `${(number / 1000).toFixed(0)}K`; // Format for thousands
    }
    return number;
};


export default About;
