import React from 'react'
import siteData from "../data"
import LocationRouterLink from "../components/LocationRouterLink"
import twoVansFall from '../assets/twoVansFall.webp'
import { getCurrentLocation } from "../tools"

const Hero = () => {
    const currentLocation = getCurrentLocation()

  return (
    <div name='home' className='w-full pt-10 h-screen bg-zinc-200 flex flex-col justify-between'>
        <div className='grid grid-cols-1 xl:grid-cols-2 max-w-[1240px] m-auto'>
            <div className='flex flex-col justify-center md:items-start w-full px-2 py-8' >
                <p className='text-2xl'>A.A. Richards Heating, Cooling, & Plumbing</p>
                <h1 className='py-3 text-5xl md:text-6xl font-bold'>Providing Comfort Since 1974</h1>
                <p className='text-2xl'>Serving Middlesex, Monmouth & Ocean County</p>
                {<a href={"tel:" + currentLocation.salesPhone.value} 
                className='py-3 button px-6 text-lg sm:w-[60%] my-4'>Call our {currentLocation.name} Office</a>}
            </div>
            <div className='hidden xl:block pt-[40px]'>
                <img className='w-full rounded-3xl' src={twoVansFall} alt="/" />
            </div>
            <div className='flex col-start-1 col-span-2 flex-col xl:mt-[-95px] mx-auto py-5 md:min-w-[700px] max-w-[1000px]
             bg-zinc-200
            border border-slate-300 rounded-xl text-center shadow-xl'>
                <p>Services</p>
                <div className='flex justify-between flex-wrap px-4'>
                    {siteData.services.slice(0, 3).map((service) => (
                        <LocationRouterLink to={service.buttonPath} key={service.name}
                            currentLocation={currentLocation}
                            className='flex cursor-pointer text-xl px-4 py-2 text-slate-500'>
                            {service.icon}{" "}{service.name}</LocationRouterLink>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero