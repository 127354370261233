import React from 'react';
import { PhoneIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import {SupportIcon} from '@heroicons/react/solid'
import LocationRouterLink from "../components/LocationRouterLink"
import supportImg from '../assets/support.jpg'
import { getCurrentLocation } from "../tools"

const Support = () => {
  const currentLocation = getCurrentLocation()

  return (
  <div name='support' className='w-full mt-24'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={supportImg} alt="/" />
      </div>
      
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-12'>
              <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Support</h2>
              <h3 className='text-5xl font-bold py-6 text-center'>Finding the right help</h3>
              <p className='py-4 text-2xl max-w-[1000px] m-auto text-center text-slate-300'>Give us a call at (732) 281-0003, and let us assist you immediately. Whether it's a small issue or something more urgent, we're here to help before it turns into a bigger problem. Don’t wait—reach out today, and let us provide you with the support and solutions you need!</p>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <PhoneIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6'>Sales</h3>
                      <p className='text-gray-600 text-xl'>Call Sales to help you find the right system, get custom recommendations, learn about discounts, explore financing, and understand warranties—all to ensure the best value and efficiency for your home.</p>
                      <div className='mt-5'>
                        <a href={"tel:" + currentLocation.salesPhone.value} className='text-blue-600 font-bold text-xl'>{currentLocation.salesPhone.display}</a>
                      </div>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                  <p className='flex items-center text-indigo-600 cursor-pointer'>
                  <LocationRouterLink to="/contactus" currentLocation={currentLocation}>Contact Us</LocationRouterLink> <ArrowSmRightIcon className='w-5 ml-2' /></p>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <SupportIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6'>Technical Support</h3>
                      <p className='text-gray-600 text-xl'>Call Technical Support to help troubleshoot system issues, provide repair guidance, assist with maintenance tips, and ensure your heating or cooling system runs efficiently, minimizing downtime and avoiding costly repairs.</p>
                      <div className='mt-5'>
                      <a href={"tel:" + currentLocation.salesPhone.value} className='text-blue-600 font-bold text-xl'>{currentLocation.salesPhone.display}</a>
                      </div>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                      <p className='flex items-center text-indigo-600 cursor-pointer'>
                      <LocationRouterLink to="/contactus" currentLocation={currentLocation}>Contact Us</LocationRouterLink> <ArrowSmRightIcon className='w-5 ml-2' /></p>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <SupportIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6'>Emergency Services</h3>
                      <p className='text-gray-600 text-xl'>Call our Emergency Services to ensure quick response to urgent issues like system breakdowns, no heating or cooling, and safety hazards, helping restore comfort and prevent further damage to your system.</p>
                      <div className='mt-5'>
                        <a href={"tel:" + currentLocation.salesPhone.value} className='text-blue-600 font-bold text-xl'>{currentLocation.salesPhone.display}</a>
                      </div>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                  <p className='flex items-center text-indigo-600 cursor-pointer'>
                    <LocationRouterLink to="/contactus" currentLocation={currentLocation}>Contact Us</LocationRouterLink> <ArrowSmRightIcon className='w-5 ml-2' /></p>
                  </div>
              </div>
          </div>
      </div>
  </div>
  );
};

export default Support;
